<script setup lang="ts">
const props = defineProps<{
  options?: any;
}>();

type PromoBannerCustomFields = {
  custom_storefront_global_show_promobanner: boolean;
  custom_storefront_global_promo_banner_richtext: string;
  custom_storefront_global_promo_banner_link: string;
};

const salesChannel = useSessionContext().sessionContext.value?.salesChannel;
const customFields: PromoBannerCustomFields = salesChannel?.translated
  ?.customFields as any;

const showFullBanner = ref<boolean>(
  import.meta.client &&
    (localStorage.getItem('bannerState') === 'open' ||
      !localStorage.getItem('bannerState'))
    ? true
    : false,
);

function toggleFullBanner() {
  showFullBanner.value = !showFullBanner.value;
  if (showFullBanner.value) {
    localStorage.setItem('bannerState', 'open');
  } else {
    localStorage.setItem('bannerState', 'closed');
  }
}
</script>

<template>
  <div
    v-if="customFields?.custom_storefront_global_show_promobanner"
    class="promobanner-wrapper fixed right-6 top-20 flex w-full justify-end lg:right-[5.25rem] lg:top-32"
  >
    <div class="relative w-full max-w-[calc(100%-4rem)]">
      <ClientOnly>
        <a
          v-if="showFullBanner"
          class="promo-banner bg-darkblue absolute right-0 flex h-10 w-fit items-center rounded-full p-2 px-4 pr-14 text-[12px] text-white"
          :href="customFields?.custom_storefront_global_promo_banner_link"
          target="_blank"
        >
          <span
            class="html-rendered"
            v-html="
              customFields?.custom_storefront_global_promo_banner_richtext
            "
          />
        </a>
        <button
          class="promo-button z-1 absolute right-0 flex h-10 w-10 items-center justify-center rounded-full"
          :class="
            showFullBanner ? 'bg-darkblue text-white' : 'text-dark bg-white'
          "
          @click="toggleFullBanner()"
        >
          <span class="sr-only">Promotion anzeigen</span>
          <i v-if="showFullBanner" class="i-carbon-close block h-5 w-5" />
          <i v-else class="i-custom-svg:megaphone block h-5 w-5" />
        </button>
      </ClientOnly>
    </div>
  </div>
</template>
